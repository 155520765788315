import React, { memo, useEffect, useState } from "react"
import style from "styles/linksBlock.module.css";
import discord from "public/image/discord.svg";
import facebook from "public/image/facebook.svg";
import twitter from "public/image/twitter.svg";
import telegram from "public/image/telegram.svg";
import instagram from "public/image/instagram.svg"
import youtube from "public/image/youtube.svg"
import medium from "public/image/medium.svg"

const LinksBlock = ({ className = "", isFooter = false}) => {
const [linkList, setData] = useState([
    {
        href: "https://www.instagram.com/metaisland_gg",
        icon: instagram,
        description: 'instagram',
        num: 0
    },
    {
        href: "https://www.youtube.com/@MetaIsland",
        icon: youtube,
        description: 'youtube',
        num: 0
    },
    {
        href: "https://twitter.com/TheMetaisland",
        icon: twitter,
        description: 'twitter',
        num: 0
    },
    {
        href: "https://www.facebook.com/Official.MetaIsland",
        icon: facebook,
        description: 'facebook',
        num: 0
    },
    {
        href: "https://t.me/metaisland_gg",
        icon: telegram,
        description: 'telegram',
        num: 0
    },
    {
        href: "https://medium.com/metaisland",
        icon: medium,
        description: 'medium',
        num: 0
    },
    {
        href: "https://discord.gg/qeZU4DqQFu",
        icon: discord,
        description: 'discord',
        num: 0
    }
]
)
const getSocialLinks = async () => {
    try {
        const request = await fetch('https://wok-os.com/social_counter.php')
        if (request.ok) {
            const postData = await request.json()
            var postDataLength = postData.length;
            const linkNextList = [...linkList];
            for (var i = 0; i < postDataLength; i++) {
                //console.log(postData[i]);
                
                const description = linkNextList.find(
                  a => a.description === postData[i].description
                );
                if (description != undefined) {
                    description.num = postData[i].num;
                }
            }
            setData(linkNextList);
        }
    } catch (e) {
    }
}
useEffect(() => {
    getSocialLinks()
}, [])
    return (
        <>
            <div className={`md:flex hidden justify-center flex-wrap`}>
                {linkList.map((item, index) =>
                    <a target="_blank" key={`item_${index}`} className={`${style.link} ${item.num != undefined && item.num != 0 && style.link_border} ${className ? className : ""}`} rel='noreferrer' href={item.href}>
                        {isFooter ? '' : item.num != undefined && item.num != 0 && (
                            <p>{item.num + 'K'}</p>
                        )}
                        <img
                            src={item.icon.src}
                            alt=""
                            width={32}
                            height={32}
                            aria-label={item.description}
                        />
                    </a>
                )}
            </div>
            <div className={`md:hidden flex justify-center`}>
                {linkList.slice(0,4).map((item, index) =>
                    <a target="_blank" key={`item_${index}`} className={`${style.link} ${item.num != undefined && item.num != 0 && style.link_border} ${className ? className : ""}`} rel='noreferrer' href={item.href}>
                        {isFooter ? '' : item.num != undefined && item.num != 0 && (
                            <p>{item.num + 'K'}</p>
                        )}
                        <img
                            src={item.icon.src}
                            alt=""
                            width={32}
                            height={32}
                            aria-label={item.description}
                        />
                    </a>
                )}
            </div>
            <div className={`md:hidden flex justify-center mt-4`}>
                {linkList.slice(4,7).map((item, index) =>
                    <a target="_blank" key={`item_${index}`} className={`${style.link} ${item.num != undefined && item.num != 0 && style.link_border} ${className ? className : ""}`} rel='noreferrer' href={item.href}>
                        {isFooter ? '' : item.num != undefined && item.num != 0 && (
                            <p>{item.num + 'K'}</p>
                        )}
                        <img
                            src={item.icon.src}
                            alt=""
                            width={32}
                            height={32}
                            aria-label={item.description}
                        />
                    </a>
                )}
            </div>
        </>
    )
}

export default memo(LinksBlock);

import React, { memo } from "react";
import style from "styles/team.module.css";
// import nicolas from "public/image/nicolas.webp";
// import laurent from "public/image/laurent.webp";
// import ethan from "public/image/ethan.webp";
// import iliah from "public/image/iliah.webp";
import nicolas from "public/image/team/nicolaschoukroun.png";
import laurent from "public/image/team/laurentcluzel.png";
import ethan from "public/image/team/ethan.png";
import iliah from "public/image/team/ilya.png";
import pascale from "public/image/pascale.webp";
import pierre from "public/image/pierre.webp";
import vitalik from "public/image/vitalik.webp";
import sanny from "public/image/team/sanny-blue.jpg";
import stephane from "public/image/stephane.webp";
import beesirin from "public/image/beesirin.webp";
import sandomierski from "public/image/sandomierski.webp";
import kriss from "public/image/team/kriss-red.jpg";
import daniel from "public/image/team/daniel-red.jpg";
import abhay from "public/image/team/abhay-blue.jpg";

import Image from "next/image";
import Link from 'next/link'
import Linkedin from './socials/linkedin'
import Elm from './socials/elm'
import Wiki from './socials/wiki'
import Git from './socials/git'
import Gamicus from './socials/gamicus'
import useLocale from '../../../../hooks/useLocale'

const Team = () => {
    const {teamSection} = useLocale()
    const teamList = [
        {
            icon: nicolas,
            name: teamSection.nicolas.name,
            profession: teamSection.nicolas.profession,
            about: teamSection.nicolas.about,
            link: "https://gamicus.fandom.com/wiki/Nicolas_Choukroun",
            type: "gamicus",
        },
        {
            icon: laurent,
            name: teamSection.laurent.name,
            profession: teamSection.laurent.profession,
            about: teamSection.laurent.about,
            link: "https://www.linkedin.com/in/laurent-cluzel-57236bb",
            type: "linkedin",
        },
        {
            icon: ethan,
            name: teamSection.ethan.name,
            profession: teamSection.ethan.profession,
            about: teamSection.ethan.about,
            link: "https://www.ethanlewismaltby.com",
            type: "elm",
        },
        {
            icon: iliah,
            name: teamSection.iliah.name,
            profession: teamSection.iliah.profession,
            about: teamSection.iliah.about,
        },
        {
            icon: daniel,
            name: teamSection.daniel.name,
            profession: teamSection.daniel.profession,
            about: teamSection.daniel.about,
        },
        {
            icon: abhay,
            name: teamSection.abhay.name,
            profession: teamSection.abhay.profession,
            about: teamSection.abhay.about,
        },
        {
            icon: kriss,
            name: teamSection.kriss.name,
            profession: teamSection.kriss.profession,
            about: teamSection.kriss.about,
        },

        // {
        //     icon: pascale,
        //     name: teamSection.pascale.name,
        //     profession: teamSection.pascale.profession,
        //     about: teamSection.pascale.about,
        //     link: "https://www.linkedin.com/in/pascale-piel-398a248",
        //     type: "linkedin",
        // },
        // {
        //     icon: pierre,
        //     name: teamSection.pierre.name,
        //     profession: teamSection.pierre.profession,
        //     about: teamSection.pierre.about,
        //     link: "https://en.wikipedia.org/wiki/Pierre_Est%C3%A8ve",
        //     type: "wiki",
        // },
        // {
        //     icon: vitalik,
        //     name: teamSection.vitalik.name,
        //     profession: teamSection.vitalik.profession,
        //     about: teamSection.vitalik.about,
        //     link: "https://github.com/mraksoll4?tab=repositorie",
        //     type: "github",
        // },
         {
             icon: sanny,
             name: teamSection.sanny.name,
             profession: teamSection.sanny.profession,
             about: teamSection.sanny.about,
         },
        // {
        //     icon: stephane,
        //     name: teamSection.stephane.name,
        //     profession: teamSection.stephane.profession,
        //     about: teamSection.stephane.about,
        //     link: "https://en.wikipedia.org/wiki/St%C3%A9phane_Picq",
        //     type: "wiki",
        // },
        // {
        //     icon: beesirin,
        //     name: teamSection.beesirin.name,
        //     profession: teamSection.beesirin.profession,
        //     about: teamSection.beesirin.about,
        // },
        // {
        //     icon: sandomierski,
        //     name: teamSection.sandomierski.name,
        //     profession: teamSection.sandomierski.profession,
        //     about: teamSection.sandomierski.about,
        // },
    ]
    return (
        <div className={style.team}>
            <div className={style.container}>
                <a href="#"><h2 className={style.title} data-aos-once="true" data-aos="slide-up">{teamSection.title}</h2></a>
                <h3 className={style.subtitle} data-aos-once="true" data-aos="slide-up">{teamSection.subtitle}</h3>
                <div className={`grid ${style.blockTeam}`} data-aos-once="true" data-aos="slide-up" data-aos-anchor-placement={"center"} data-aos-duration={300}>
                    {teamList.map((item, index) =>
                        <div key={`item_${index}`} className={style.teammate}>
                            <div className={style.blockImg}>
                                <div className={style.img}>
                                    <Image
                                        src={item.icon}
                                        alt=""
                                        layout='responsive'
                                        objectFit='contain'
                                    />
                                </div>
                                {item.link && (
                                    <Link href={item.link}>
                                        <a target="_blank" className={style.float} aria-label="team">
                                            {item.type === 'linkedin' && (
                                                <Linkedin width="100%" height="100%" />
                                            )}
                                            {item.type === 'elm' && (
                                                <Elm width="100%" height="100%" />
                                            )}
                                            {item.type === 'wiki' && (
                                                <Wiki width="100%" height="100%" />
                                            )}
                                            {item.type === 'github' && (
                                                <Git width="100%" height="100%" />
                                            )}
                                            {item.type === 'gamicus' && (
                                                <Gamicus width="100%" height="100%" />
                                            )}
                                        </a>
                                    </Link>
                                )}
                            </div>
                            <h2 className={style.name}>{item.name}</h2>
                            <p className={style.profession}>{item.profession}</p>
                            {item.about && (
                                <p className={style.about}>{item.about}</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default memo(Team);

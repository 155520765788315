import {ILocale} from "./type"

const sp: ILocale = {
	header: {
		blog: "Blog",
		enterWokOS: "Entrar WOLF-OS",
		enterNFT: "NFT Shop",
		features: "Función",
		franshise: "Francia",
		vision: "Juego",
		nft: "NFT",
		staking: "Staking",
		roadmap: "Hoja de ruta",
		token: "Token",
		bookOfKnowlage: "Libros",
	},
	
	playSection: {
		openWorld: "ai gaming mundo abierto metaverso gamefi",
		sound: "Sonido: ",
		on: "en",
		off: "apagada"
	},
	subscribeSection: {
		dontMiss: "Don\'t miss the boat",
		weAreGoing: "Nosotros vamos a comercializar pronto",
		beTheFirst: "Sé el primero en enterarte y no pierdas la oportunidad",
		subscribe: "Suscribir",
		success: "¡Tus datos han sido enviados! nos vemos en la isla!",
		networkError: "Error de red",
		emailError: "Email incorrecto",
		placeholder: "Dirección de correo electrónico",
		joinToFreeNft: "Subscribe today and",
		groupName: "join our discord",
		claimYourNft: "to claim your",
		freeNft: "FREE",
		claimNft: "NFT Wolf reward. Escape reality, join the tribe",
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."		
	},
	aigamingSection: {
		title:"AI Gaming",
		definition1: "Gaming AI is a revolutionary way to use AI to create non-linear gameplay. This new approach will enable metaverses and persistent virtual worlds to entertain their participants without limitations.", definition2: "While normal games, even open-world RPGs, are linear and require players to complete certain tasks in a specific order to reach the end, the Gaming AI approach senses the player\'s abilities and adapts the difficulty, events, and gaming experience to their social status, rank, weapons, and abilities. ",
		definition3: "This creates a never-ending game that can be played in any order with total freedom of movement. This new approach to gaming will allow metaverses to offer high-quality gamification in a persistent world."
	},	
  sliderSection: {
    firstSlideTitle: "PROTEGE LA ISLA, \n Y TUS AMIGOS \n De los mutantes",
    firstSlideText: "Ve a comprobar si todo el mundo está bien, si la electricidad sigue encendida y si las protecciones contra \nlos malvados siguen funcionando.",
    firstStory: "Mutantes",
    secondStory: "Mundo abierto",
    thirdStory: "Juega para ganar",
  },
	mapSection: {
		title: "Muchos lugares para explorar",
		firstAdvantageTitle: "8 000 000 m²",
		firstAdvantageText: "plaza de una isla",
		secondAdvantageTitle: "1 000 gente",
		secondAdvantageText: "Puede jugar en la isla al mismo tiempo.",
		thirdAdvantageTitle: "> 100 000",
		thirdAdvantageText: "Árboles, rocas, gente y muchos lugares para visitar",
	},
	charactersSection: {
		title: "Personaliza tu personaje con NFT",
		text: "Metaisland es una plataforma de redes sociales, con avatar virtual, chats, amistad, estado y me gusta. Puedes elegir tu personaje entre +150 NFT 3d únicos.",
	},
	learnTheStorySection: {
		title: "Aprende la historia",
		firstStory: "The History",
		secondStory: "alquilar una tienda",
		thirdStory: "Lucha contra mutantes",
		fourthStory: "Explora el océano",
	},
	nftSection: {
		title: "NFT 2D único",
		button: "Ir a la tienda NFT",
		aquamen: "Aquamen",
		military: "Military",
		punks: "Punks",
		redcards: "Redcards",
		tribes: "Tribes",
		builders: "Builders",
		wolfWood: "Wolf Wood",
		wolfIron: "Wolf Iron",
		daemons: "Daemons"
	},
	playToEarnSection: {
		title: "Juega 2 gana",
		subtitle: "Metaisland es una plataforma de redes sociales, con avatar virtual, chats, amistad, estado y me gusta. Puedes elegir la piel de tu personaje y construir tu propia personalidad con características de RPG..",
		earn: "Ganar",
		earnFirstTitle: "Encuentra criptomonedas",
		earnFirstText: "Airdrops y piedras mineras",
		earnSecondTitle: "ganar misiones",
		earnSecondText: "Obtener misiones de NPC",
		earnThirdTitle: "alquilar un negocio",
		earnThirdText: "Se alquilan restaurantes o casas de cambio",
		spend: "Gastar",
		spendFirstTitle: "NFT",
		spendFirstText: "Audio, Arte, Máscaras, Objetos y Habilidades",
		spendSecondTitle: "Comprar comida",
		spendSecondText: "Necesitas administrar tus recursos",
		spendThirdTitle: "Beber alcohol",
		spendThirdText: "La cerveza también te ayuda a cargar tu energía",
	},
	soonSection: {
		title: "Próximamente, en breve, pronto",
		subtitle: "Franquicia metaverso original",
		soonFirstTitle: "Motor de juego avanzado",
		soonFirstText: "Meta Island procesa la participación en 3D en tiempo real en un intrigante mundo abierto cooperativo, hecho con Unreal Engine 5",
		soonSecondTitle: "Componibilidad máxima",
		soonSecondText: "Gane DeFi, juegue, chatee con otros, participe en DAO y experimente una economía Web3 con Holochain para Genesis Meta Island y sus usuarios.",
		soonThirdTitle: "Isla Génesis y franquicias",
		soonThirdText: "Amplíe su horizonte Web3 con la tecnología Fintech de primer nivel. Aproveche la oportunidad ya preparada de Genesis Island o franquiciando su propia versión!",
	},
	roadmapSection: {
		title: "Mapa vial",
		first: "Ventas de NFT en nuestro mercado",
		second: "Primera demo jugable con la primera isla",
		third: "Sistema de diálogo, Aventura, Cuentacuentos",
		fourth: "Sistema de combate. desarrollo vertical",
		fifth: "Franquicias y desarrollo horizontal, desarrollo de negocio",
		button: "Read more about Roadmap",
	},
	blogSection: {
		title: "Blog",
		button: "Leer más publicaciones",
	},
	teamSection: {
		title: "W.O.K Corp Studio",
		subtitle: "W.O.K Corp Studio  es +20 personas talentosas repartidas por todo el mundo",
		nicolas: {name: "Nicolas Choukroun", profession: "CEO", about: "Ubisoft, Mindscape, Fox, Loriciel, Cryo, EA, Euronext"},
		laurent: { name: "Laurent Cluzel", profession: "Director de gráficos", about: "Ubisoft, Sony, Psygnosis, Wizama, EA, Namco"},
		ethan: { name: "Ethan Lewis Maltby", profession: "Directora de Sonido y Música", about: "UEFA, FA, NFL, RBS, Microsoft, Nestle, Cineworld, Telenet, BP, Wella, Walls, Leeds Castle, KCC"},
		iliah: {name: "Ilya Cerberex", profession: "Lead Blockchain Specialist", about: "Python, Php, C++, Full Stack, NEO Chain, Solidity, Hosting"},
		pascale: {name: "Pascale Piel", profession: "CFO", about: "Antabio SAS, Theravectys, Sofrepost, Novexel, Axa"},
		pierre: { name: "Pierre Esteve", profession: "Guionista Músico", about: "Arte, Cine, Televisión, Starsync"},
		vitalik: { name: "Vitalik Mraksoll", profession: "Desarrollador de blockchain", about: "C++, React, Python, Solidity, Rust"},
		sanny: {name: "Sanni Muhammed", profession: "Head of Community Manager", about: "Twitter, Reddit, Discord"},
		daniel: {name: "Daniel Morar", profession: "Lead Unreal developer", about: ""},
		kriss: {name: "Krzysztof Sandomierski", profession: "Project Manager & Tester", about: ""},		
		abhay: {name: "Abhay Prakashchandra Mahajan", profession: "Backend / WOK-OS Api", about: "PHP/Mysql specialist"},		
		stephane: {name: "Stephane Picq", profession: "Music Composer", about: ""},
		beesirin: {name: "Beesirin Impaw", profession: "General Manager Thailand", about: ""},
		sandomierski: {name: "Sandomierski Krzysztof", profession: "Project Manager", about: ""},	},
		advisorySection: {
		title: "Advisory",
		hassan: {name: "Hassan (Hatu) Sheikh", profession: "Token Economy, Fundraising", about: "Daomaker Co-founder. Founder of ApeTerminal.io"},
		iftikhar: {name: "Iftikhar Qasim", profession: "Senior Advisor SL2 Capital & Hillstone Finance", about: ""},
		serge: {name: "Serge Hascoet", profession: "Chief Creative Officer at Ubisoft for +30 years", about: ""},
		david: {name: "David Atkinson", profession: "Token Economy Advisor", about: "Holochain co-founder"},
		andrey: {name: "Andrey He", profession: "Marketing & Fundraising", about: "NEO chain"},
		babar: {name: "Babar Shabir", profession: "NFT and Blockchain Stratergy", about: ""},
		konstantin: {name: "Konstantin Pechesky", profession: "President of Trinity, investment & crypto expert", about: "Trinity - Venture Capital Club"},				
	},
	partnersSection: {
		title: "Socios",
		subtitle: "The Team has published games or worked for these following companies",
		investors: "Inversores semilla",
		technologyAndLegalPartners: "Socios tecnológicos y legales",
		wizama: "Constructores de juegos de mesa mágicos desde 2017",
		nintendo: "multinacional japonesa de videojuegos",
		sega: "Empresa multinacional japonesa de videojuegos y entretenimiento",
		ea: "empresa estadounidense de videojuegos",
		sony: "Corporación de conglomerado multinacional japonesa",
		mindscape: "desarrollador de videojuegos francés",
		cryo: "Desarrollo de videojuegos francés",
		ubisoft: "empresa francesa de videojuegosGerente de proyecto",
	},
	popUpMining: {
		title: "Área de Minería y Airdrop",
		text: "Cada 2 horas, suena una alarma y se lanza un airdrop. Todas las criptomonedas que los jugadores no almacenan en caché después de 4 minutos se destruyen automáticamente.",
		more: "Más"
	},
	popUpFactory: {
		title: "Electric Factory",
		text: "En el juego, todo el sistema eléctrico está totalmente simulado. Los cables son dignos de todas las áreas e incluso hay algunos paneles solares. Las bobinas de Tesla envían la energía a la fábrica eléctrica donde luego se administra, almacena y entrega al resto de la isla. También hay formas de encender y apagar la electricidad en esta área..",
		more: "Más",
	},
	popUpCyber: {
		title: "Cyber area",
		text: "El área cibernética es uno de los edificios más grandes de la isla. Unas 500 personas pueden encontrar una habitación allí. Es un lugar salvaje con alcohol, drogas y prostitutas..",
		more: "Más",
	},
	popUpSlum: {
		title: "Slum Ville",
		text: "Este es el lugar más barato para conseguir una habitación cuando estás empezando el juego. Está lejos del área de minería/lanzamiento aéreo, pero tiene un ambiente agradable. Bueno para empezar el juego...",
		more: "Más",
	},
	popUpTech: {
		title: "Tech Ville",
		text: "Tech Ville es el área administrativa. Con salas de reuniones y la estación de teletransportador. También hay personas conocedoras de la zona, donde obtener información sobre la isla. El área cuenta con un museo, algunas casas, la estación de teletransportación, algunos restaurantes, una plaza de comida y un supermercado.",
		more: "Más",
	},
	popUpLeaw: {
		title: "Leaw Beach",
		text: "Leaw Beach es la primera playa para visitar. Actualizaremos el juego para tener muchos más. Es el lugar para relajarse y disfrutar de una discoteca o un bar.",
		more: "Más",
	},
	popUpTesla: {
		title: "Tesla Coil área",
		text: "Estas áreas están generando la energía para todas las estructuras de la isla. La energía proviene de las criptominas bajo tierra. Hay varias áreas de Tesla repartidas en la isla, algunas están en la Zona Salvaje y son peligrosas porque se autoprotegen al destruir a los humanos que se acercan. El área de Tesla Energy está ubicada cerca de Techville, y los jugadores deben asegurarse de que siempre esté funcionando. Hay formas de encenderlo y apagarlo, y algunos jugadores tendrán que adquirir el conocimiento para evitar cualquier corte de energía en la infraestructura que pueda poner la zona protegida en riesgo de ser invadida por las criaturas de la Zona Salvaje.",
		more: "Más",
	},
	popUpTribe: {
		title: "Tribe Village",
		text: "Este es el lugar más barato para conseguir una habitación cuando estás comenzando el juego. Está lejos del área de minería/lanzamiento aéreo, pero tiene un ambiente agradable. Bueno para empezar el juego..",
		more: "Más",
	},
	footer: {
		whitepaper: "Papel blanco",
		pitchdeck: "Pitch Deck Detailed",
		pitchdecklite: "Pitch Deck Lite",
		metaisland: "METAISLAND",
	},
	islToken: {
		title: "ISL simbólica",
		subtitle: "El token ISL es el token ETH principal del ecosistema Metaisland: el ecosistema de capa 1, Metaverse, \n NFT y GameFi nativo. La economía de tokens incluye apuestas, bloqueos, incentivos de liquidez, recompras y quemas. Estos están impulsados ​​\n por la demanda atraída tanto por las ventajas del juego como por el \n valor extraído de la economía del metaverso.",
		tokenEconomy: "Economía de fichas",
		playIncome: "Ingresos de juego",
		playIncomeFirstTitle: "Suscripción mensual",
		playIncomeFirstText: "El Metaverse es un juego gratuito, pero para desbloquear todas las posibilidades, los participantes deben pagar una tarifa mensual de $10 como mínimo..",
		playIncomeSecondTitle: "Comercialización",
		playIncomeSecondText: "Metaisland es una franquicia con personajes e historias, que nos permiten crear todo tipo de merchandising como en cualquier franquicia popular..",
		playIncomeThirdTitle: "Juego apostado",
		playIncomeThirdText: "Los jugadores pueden alquilar una tienda para tener un MPR (Porcentaje de ingresos mensuales). El porcentaje de participación depende de la ubicación de la tienda y el éxito..",
		nftIncome: "Ingresos NFT",
		nftIncomeFirstTitle: "Mercado NFT",
		nftIncomeFirstText: "Se aplica una tarifa del 2,5 % en todas las operaciones de NFT en el mercado de NFT integrado en la aplicación",
		nftIncomeSecondTitle: "Ventas NFT",
		nftIncomeSecondText: "Nuestros NFT están desbloqueando funciones en Metaverse, los jugadores tendrán que recopilar muchos de ellos para acceder a todas estas funciones..",
		nftIncomeThirdTitle: "Alquileres NFT",
		nftIncomeThirdText: "El jugador puede alquilar nuestros NFT con su dinero Play to Earn, y también puede alquilar los NFT que posee para ganar dinero extra..",
	},
	tokenEconomy: {
		firstTitle: "Valor del token ISL de Game Economy",
		gameEconomyFirstTitle: "recompras",
		gameEconomyFirstText: "El 50 % de los ingresos del juego se utiliza para comprar tokens en el mercado.",
		gameEconomySecondTitle: "quemaduras",
		gameEconomySecondText: "El gobierno puede votar para quemar hasta el 50% de los tokens recomprados",
		gameEconomyThirdTitle: "Recompensas de participación",
		gameEconomyThirdText: "Hasta el 50% de los tokens comprados en el mercado se distribuyen a los interesados",
		secondTitle: "Valor del token ISL \n en el ecosistema",
		ecosystemFistTitle: "replanteo",
		ecosystemFistText: "Al apostar tokens y tokens LP, se desbloquean ciertas máscaras de WOK y Avatar. Los avatares son visibles en la tabla de clasificación, para los amigos y en el mapa público.",
		ecosystemSecondTitle: "Descuentos",
		ecosystemSecondText: "Se cobra una tarifa de regalías del 2,5% en las transacciones de juegos NFT, es decir, máscaras. Esta tarifa tiene un descuento del 2% si el jugador está apostando una cantidad mínima de fichas de juego",
		ecosystemThirdTitle: "recompras",
		ecosystemThirdText: "El 50% de las tarifas comerciales de NFT se recompran en el mercado, que el DAO puede votar para quemar",
		ecosystemFourthTitle: "Rendimiento de apuesta",
		ecosystemFourthText: "Hasta el 50% de las tarifas comerciales de NFT están disponibles como recompensas de participación",
	},
	tokenDistribution: {
		title: "Distribución de tokens",
		secondTitle: "Total: 100% — 1,000,000,000",
	},
	visionMetaisland: {
		title: "¿Qué es Metaisla??",
		subtitle: "Una infraestructura de metaverso para miles de millones de usuarios",
		text: "Metaisland es una infraestructura de cadena de bloques personalizada, creada para Metaverse. Compatible con las billeteras más populares, conectado con todas las principales cadenas de bloques, y que combina defi, NFT y juegos para ganar en un concepto revolucionario único.",
	},
	metaverseBlock: {
		firstTitle: "La economía del metaverso vale $ 13 billones para 2030",
		secondTitle: "Un metaverso para todos",
		subtitle: "La firma de Wall Street, Morgan Stanley, ve el metaverso como un mercado direccionable de $ 8 billones que probablemente se convierta en la \"plataforma de juegos, transmisión y redes sociales de próxima generación\".\n" +
"La ciudad es aún más optimista y dijo: \"Creemos que Metaverse es la próxima generación de Internet, que combina el mundo físico y digital de una manera persistente e inmersiva, y no un mundo puramente de realidad virtual\", se lee en el informe. Un metaverso independiente del dispositivo accesible a través de PC, consolas de juegos y teléfonos inteligentes podría dar como resultado un ecosistema muy grande",
	    date: "(Fortuna Abril 2022)",
		FirstTitle: "Metaisland ISL token de utilidad",
		FirstText: "The ISL Token es el combustible que empodera a Metaisland. Los propietarios de islas deben comprar el token ISL en el mercado para recargar su tanque de recompensa. ",
		SecondTitle: "2D NFT multicadena",
		SecondText: "9 colecciones de 2d PFP NFT están disponibles, todas con una utilidad. Estarán disponibles en múltiples cadenas y en muchos mercados diferentes",
		ThirdTitle: "3d NFT con funciones de juego de rol",
		ThirdText: "Nuestros 3d NFT se pueden jugar directamente en el juego y tienen funciones de juego de rol como fuerza, velocidad, vida, maná, hambre, sed, etc....",
		FourthTitle: "Tienda NFT, para comprar o alquilar",
		FourthText: "¡Nuestros NFT se pueden comprar con efectivo, criptomonedas o incluso alquilar! No hace falta ser rico para disfrutar de Metaisland.",
		FifthTitle: "WOK-OS para gestionar tu DNI",
		FifthText: "El WOK-OS es nuestro backend para administrar la identidad de los usuarios, garantiza la privacidad de cada participante de Metaverse. ",
		SixthTitle: "Metaisland Franquicia",
		SixthText: "Los mejores usuarios de Metaverse obtienen acceso para administrar su propia isla y ganarse la vida administrando una Metaisland propia.",
		firstAdvantageTitle: "Suscripciones con efectivo",
		firstAdvantageText: "One Island con 1000 jugadores está generando 20 000,00 USD al mes en ventas",
		secondAdvantageTitle: "Suscripciones con cripto",
		secondAdvantageText: "1 millón de jugadores generaría millones de USDT en ventas por mes",
		thirdAdvantageTitle: "Ventas NFT",
		thirdAdvantageText: "Si bien los NFT no son obligatorios para jugar, sus atributos únicos los hacen muy atractivos.",
		fourthAdvantageTitle: "Regalías NFT",
		fourthAdvantageText: "El 10 % por cada reventa puede generar un enorme flujo de ingresos con el tiempo",
		fifthAdvantageTitle: "Franquiciamiento",
		fifthAdvantageText: "La franquicia nos permite convertirnos en proveedores de contenido y vender claves API sin tener que localizarnos en todos los países.",
		sixthAdvantageTitle: "Comercialización",
		sixthAdvantageText: "Camisetas, tazas, recuerdos y otros artículos relacionados con el juego a la venta en la tienda en línea",
	},
	visionSlider: {
		title: "Metaisland ofrece un metaverso para todos",
		subtitle: "Metaisland es una plataforma de redes sociales, con avatar virtual, chats, amistad, estado y me gusta. Puedes elegir un personaje entre más de 150 personajes 3D únicos y crear tu propia personalidad usando características similares a las de los juegos de rol.",
	},
	booksPlay: {
		title: "Metaisland",
		text: "TIENE serie ÚNICA de Libro",
	},
	books: {
		title: "elige tu libro",
		firstTitle: "Red Cards",
		firstDescription: "Descripción",
		firstText: "Por Nicolas Choukroun, Nick Brown",
		secondTitle: "Tribes",
		secondDescription: "Descripción",
		secondText: "Por Nicolas Choukroun, Nick Brown",
		thirdTitle: "Aquamen",
		thirdDescription: "Descripción",
		thirdText: "Por Nicolas Choukroun, Nick Brown",
		fourthTitle: "Military",
		fourthDescription: "Descripción",
		fourthText: "Por Nicolas Choukroun, Nick Brown",
		fifthTitle: "Punks",
		fifthDescription: "Descripción",
		fifthText: "Por Nicolas Choukroun, Nick Brown",
		sixthTitle: "Daemons",
		sixthDescription: "Descripción",
		sixthText: "Por Nicolas Choukroun, Nick Brown",
		seventhTitle: "Builders",
		seventhDescription: "Descripción",
		seventhText: "Por Nicolas Choukroun, Nick Brown",
		eightsTitle: "Wolf wood",
		eighthDescription: "Descripción",
		eightsText: "Por Nicolas Choukroun, Nick Brown",
		ninthTitle: "Wolf iron",
		ninthDescription: "Descripción",
		ninthText: "Por Nicolas Choukroun, Nick Brown",
	},
	stakingSlider: {
		title: "Replantear es en realidad alquilar una tienda",
		subtitle: "Puede poseer o alquilar nuestros NFT 3D. Cada serie tiene sus características específicas inspiradas en los Juegos de Rol (RPG). Son jugables dentro del juego.",
		secondTitle: "Haga clic para alquilar su propia tienda",
		button: "ir a alquiler",
	},
	stakingMechanism: {
		title: "Metaisland",
		subtitle: "TIENE UN MECANISMO DE PARTICIPACIÓN ÚNICO",
		button: "Ir a Replantear",
		firstStep: "ALQUILA UNA TIENDA EN METAISLANDA",
		secondStep: "CADA COMERCIO TIENE SU TPM (Tasa Porcentual Mensual)",
		thirdStep: "CADA MES COBRA % MPR DEL PRECIO DE ALQUILER",
	},
	nftMechanism: {
		title: "Metaisland",
		subtitle: "TIENE características ÚNICAS de NFT",
		button: "Ir a la TIENDA NFT",
		firstStep: "Ir a la tienda NFT",
		secondStep: "Puede poseer o alquilar NFT, pagar en efectivo, billetera",
		thirdStep: "Ahora puedes usar tu NFT dentro del Metaverso",
	},
	pageNotFound: {
		title: "404 - Página no encontrada",
		subtitle: "¿Echas de menos algo??",
		button: "Ve a la página principal",
	},
	sinceTimer: {
		title: "We are building Metaisland for",
				subtitle: "Follow us and join our community!",
		second: "second",
		minute: "minute",
		hour: "hour",
		day: "day",
		month: "month",
		year: "year",
		seconds: "seconds",
		minutes: "minutes",
		hours: "hours",
		days: "days",
		months: "months",
		years: "years",
	},
	subscribeEmail: {
		title: "Survivor, you did it!",
		alreadySub: "Already joined!",
		subtitle: "Welcome to Metaisland! \nYou have taken the first step in escaping the brutal reality and boredom of reality!\n At Metaisland, we are creating a future virtual reality, with an economy a social system and plenty of ways to entertain yourself in a toxic free environment. A decentralized place where freedom of speech is respected and where you can enjoy meeting friends and have create a different personality, do things extraordinary and live in an intriguing Island full of mystery. \nYou can enjoy a beer and relax, talk with friends, or go after the Mutants in the Wild Zone and have a lot of action, fights and explosions. You can also monetize the time spent in the game with all kind of DEFI possibilities that no other entertainment product is proposing. \nMetaverses are the FUTURE! And no need to wait long time to participate, you can join today this revolution. \nAs a token of appreciation for joining us on our journey of building Metaisland, we are delighted to offer you an opportunity to win a free NFT \nThis NFT is a FOUNDER NFT and will have the unique possibility to enter any Metaisland without asking for citizenship, as a VIP member of the project. Don\’t miss this opportunity!",
		toRedeem: "To redeem this Free NFT, follow these steps.",
		discord: "Discord",
		joinDiscord: "Join our Discord server:",
		crew: "Crew3",
		joinCrew: "Go to our Crew 3 page and complete the tasks:",
		bottomText: "Check out our weekly podcast series, Meta Talks, for all the latest info on Metaverse. Nikko Founder and CEO from Metaisland has got you covered, so be sure to join in and learn more about Crypto, GameFi, and the Metaverse itself. \nLet\’s build Metalsland together!",
		talkWith: "Meta Talk With Nikko CEO MetaIsland - WIN FREE NFT - YouTube",
		button: "Go to the Main Page",
	},
	box: {
		title: "Haga clic para generar CAJA MÁGICA NFT!",
		button: "Generar",
		nftTitle: "Oferta exclusiva para los primeros usuarios",
		nftSubtitle: "¡Recibirás 3 NFT por el precio de uno! Solo por el precio de 0.1 ETH",
		firstNftTitle: "Daemons#41",
		firstNftText: "Avatares NFT 2D",
		secondNftTitle: "Charlie#08",
		secondNftText: "Avatares NFT 2D",
		thirdNftTitle: "Wolf wood#175",
		thirdNftText: "Avatares NFT 2D",
	},
	browseNft: {
		title: "METAISLAND 3D NFT",
		subtitle: "Puede poseer o alquilar NFT 3D. Cada serie tiene sus características específicas inspiradas en los Juegos de Rol (RPG). Se pueden jugar dentro del juego y se ven exactamente así",
		secondTitle: "Haga clic para EXAMINAR NFT",
		button: "Navegar",
		firstNftTitle: "John#04",
		firstNftText: "Avatares NFT 3D",
		secondNftTitle: "Ariana#05",
		secondNftText: "Avatares NFT 3D",
		thirdNftTitle: "Assassin#01",
		thirdNftText: "Avatares NFT 3D",
	},
	mysterySlider: {
		title: "METAISLAND 2D NFT",
		subtitle: "Nuestro perfil 2D NFT tiene características específicas, como dar la capacidad de nadar o ser inmune al veneno. Tenemos 7 Facciones, cada una de ellas con una historia y con utilidad en el juego. Los jugadores pueden tener una cartera de NFT que pueden usar en el metaverso para agregar más capacidades a su personaje 3D",
		secondTitle: "Haz clic para obtener más información sobre las facciones.",
		button: "Libro del conocimiento",
		secondButton: "Navegar NFT WOLF PASES",
		wolfTitle: "METAISLAND WOLF PASAR",
		wolfText: "Nuestros Wolf Passes se utilizan para dar acceso a un lugar secreto escondido en la isla. También se utilizan como derecho de voto para nuestro D.A.O y acceso a Betas privadas. Finalmente, son geniales y creativos, y son geniales para usar como foto de perfil en tu red social favorita. Todos nuestros NFT tienen 2 versiones, una limpia sin texto y otra con texto mejorado.",
		nftTitle: "Pase de WOLF",
		nftText: "Metaisland",
	},
}


export default sp
